// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Form Styles */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Topic Field Specific Styles */
.form-group.topic input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Description Field Specific Styles */
.form-group.description textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  height: 150px;
}

/* Form Submission Button Styles */
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Responsive Adjustments for Small Screens */
@media only screen and (max-width: 600px) {
  .form-group.topic input,
  .form-group.description textarea {
    padding: 6px;
  }

  button[type='submit'] {
    padding: 6px 10px;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/suggestions/NewSuggestion.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA,gCAAgC;AAChC;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA,sCAAsC;AACtC;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA,kCAAkC;AAClC;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA,6CAA6C;AAC7C;EACE;;IAEE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF","sourcesContent":["/* General Form Styles */\n.form-group {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n}\n\n.form-group label {\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n/* Topic Field Specific Styles */\n.form-group.topic input {\n  width: 100%;\n  padding: 8px;\n  box-sizing: border-box;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n/* Description Field Specific Styles */\n.form-group.description textarea {\n  width: 100%;\n  padding: 8px;\n  box-sizing: border-box;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  resize: vertical;\n  height: 150px;\n}\n\n/* Form Submission Button Styles */\nform {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n/* Responsive Adjustments for Small Screens */\n@media only screen and (max-width: 600px) {\n  .form-group.topic input,\n  .form-group.description textarea {\n    padding: 6px;\n  }\n\n  button[type='submit'] {\n    padding: 6px 10px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
