import 'ultimate-react-multilevel-menu/dist/esm/index.css';
import { useAppContext } from '../context/appContextProvider';
import { Navbar, Collapse, Item, Items, Logo } from 'ultimate-react-multilevel-menu';

const Menu = ({ onLogout }) => {
  const { userID } = useAppContext();

  const handleLogout = () => {
    var body = {
      userID: userID,
    };

    onLogout(body);
  };

  return (
    <Navbar className={'navbar-light bg-white'}>
      <Collapse>
        <Logo href="/">Home</Logo>
        <Item href={'/suggestionBox'}>Suggestion Box</Item>
        <Item href={'/voting'}>Voting</Item>
        <Item href={'/map'}>Map</Item>
        <Item href={'/funds'}>Funds</Item>
        <Items title="Documents">
          <Item href={'/CCRs'}>Covenents, conditions and restrictions</Item>
          <Item href={'/weedplan'}>Weed plan</Item>
          <Item href={'/deq'}>DEQ</Item>
        </Items>
        <Item href={'/userInfo'}>My Info</Item>
        <Item href={'/about'}>About</Item>
      </Collapse>
      <Item onClick={handleLogout}>Logout</Item>
    </Navbar>
  );
};

export default Menu;
