import { useState } from 'react';
import './CommentsAccordion.css'; // Create and update this CSS file if needed

const CommentsAccordion = ({ showAdd, comments, totalComments, onAddComment }) => {
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');

  const handleAddComment = () => {
    const trimmedComment = comment.replace(/\s+/g, ''); // Removes all whitespace

    if (trimmedComment.length === 0) {
      setCommentError('Comments must have text');
      return;
    }
    onAddComment(comment);
    setComment('');
    setCommentError('');
  };

  return (
    <div className="comments-accordion">
      <div className="comments-header" onClick={() => setCommentsOpen(!commentsOpen)}>
        <p>Comments ({totalComments})</p>
        <span className={`caret ${commentsOpen ? 'rotate' : ''}`}>▼</span>
      </div>

      {commentsOpen && (
        <div className="comments-body">
          {comments.map((comment) => (
            <div key={comment._id} className="comment">
              <p>{comment.comment}</p>
              <small>{new Date(comment.date).toLocaleString()}</small>
            </div>
          ))}
        </div>
      )}

      {showAdd && (
        <div className="add-comment-container">
          <div className="add-comment-input-container">
            {commentError && <div className={`error-message ${commentError && 'error-text'}`}>{commentError}</div>}
            <input type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
          </div>

          <button onClick={handleAddComment}>
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default CommentsAccordion;
