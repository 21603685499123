// import { useEffect, useState, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
// import { useAppContext } from '../context/appContextProvider';

// import { base_url } from '../../environment/url';
// import axios from '../axiosApi/axiosApi';

import '../../global.css';
// import './FundsDetail.css';

const balanceSheet_debugThis = false;
const what = 'balanceSheet';

const BalanceSheet = ({ fiscalYear, details }) => {
  // const { userID } = useAppContext();
  // const { id, name } = useParams();
  // const [details, setDetails] = useState([]);

  // const fetchFundDetails = useCallback(async () => {
  //   const debugThis = false || fundsDetail_debugThis;
  //   const msgPrefix = what + '.fetchFundDetails';

  //   try {
  //     const url = base_url + `/funds/id/${id}`;

  //     if (debugThis) {
  //       console.log(msgPrefix, 'URL', url);
  //     }

  //     const response = await axios.get(url);

  //     if (debugThis) {
  //       console.log(msgPrefix, 'response', JSON.stringify(response.data));
  //     }

  //     setDetails(response.data);
  //   } catch (error) {
  //     console.error(msgPrefix, 'Error', error);
  //   }
  // }, [setDetails]);

  // component mounting
  // useEffect(() => {
  //   const fectchData = async () => {
  //     await fetchFundDetails();
  //   };

  //   fectchData();
  // }, [fetchFundDetails]);

  return <h4>TODO - Balance Sheet {fiscalYear}</h4>;
};

export default BalanceSheet;
