// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fund-action-box {
  border: 2px solid #ccc; /* Change to your desired border color */
  border-radius: 8px; /* Optional: adds rounded corners */
  background-color: #f9f9f9; /* Optional: adds a background color */
  margin-bottom: 30px;
}

.fund-action-container {
  margin: 10px;
  margin-bottom: 0px;
}

.fund-action-title {
  font-size: 20px;
  color: #444;
  margin-top: 5px;
  margin-bottom: 5px;
}

.action-selection {
  margin-bottom: 20px;
}

.action-selection label {
  margin-right: 10px;
}

.action-selection input[type='radio'] {
  margin-right: 5px; /* increase/decrease the space between radio and text*/
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  .fund-action-container {
    margin: 10px;
    margin-bottom: 40px;
  }

  .fund-action-title {
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/funds/FundActions.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,wCAAwC;EAChE,kBAAkB,EAAE,mCAAmC;EACvD,yBAAyB,EAAE,sCAAsC;EACjE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB,EAAE,sDAAsD;AAC3E;;AAEA,kBAAkB;AAClB;EACE;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".fund-action-box {\n  border: 2px solid #ccc; /* Change to your desired border color */\n  border-radius: 8px; /* Optional: adds rounded corners */\n  background-color: #f9f9f9; /* Optional: adds a background color */\n  margin-bottom: 30px;\n}\n\n.fund-action-container {\n  margin: 10px;\n  margin-bottom: 0px;\n}\n\n.fund-action-title {\n  font-size: 20px;\n  color: #444;\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n\n.action-selection {\n  margin-bottom: 20px;\n}\n\n.action-selection label {\n  margin-right: 10px;\n}\n\n.action-selection input[type='radio'] {\n  margin-right: 5px; /* increase/decrease the space between radio and text*/\n}\n\n/* Mobile Styles */\n@media screen and (max-width: 600px) {\n  .fund-action-container {\n    margin: 10px;\n    margin-bottom: 40px;\n  }\n\n  .fund-action-title {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
