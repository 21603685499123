import React, { useEffect } from 'react';

import myFile from '../DEQ.pdf';

const Deq = () => {
  useEffect(() => {
    // Function to open the document
    const openDocument = () => {
      window.open(myFile, '_blank', 'noopener,noreferrer');
    };

    // Delay opening the document by 2 seconds (2000 milliseconds)
    const timer = setTimeout(() => {
      openDocument();
    }, 500);

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <p>
        If the document did not open automatically, you can{' '}
        <a href={myFile} target="_blank" rel="noopener noreferrer">
          click here to view it
        </a>
        .
      </p>
    </div>
  );
};
export default Deq;
