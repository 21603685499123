import PlotClicker from './plotClicker';

import './InteractiveMap.css'

const InteractiveMap = () => {
  return (
    <div className="interactive-map-container">
      <div>
        <h1>Map</h1>
        <p>Clicking on a lot will display information provided by owner.</p>
        <p>Claiming a lot is on the honor system.</p>
      </div>

      <PlotClicker />
    </div>
  );
};

export default InteractiveMap;
