// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plotInfo-owners-container {
  margin-left: 10px;
}

.plotInfo-owners-list {
  margin-left: 10px;
}

.plotInfo-button-container {
  margin-left: 10px;
  display: flex; /* To align buttons side by side */
  gap: 10px; /* Adds space between the buttons */
  align-items: center; /* Vertically center buttons */
}
`, "",{"version":3,"sources":["webpack://./src/components/plot/plotInfo.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa,EAAE,kCAAkC;EACjD,SAAS,EAAE,mCAAmC;EAC9C,mBAAmB,EAAE,8BAA8B;AACrD","sourcesContent":[".plotInfo-owners-container {\n  margin-left: 10px;\n}\n\n.plotInfo-owners-list {\n  margin-left: 10px;\n}\n\n.plotInfo-button-container {\n  margin-left: 10px;\n  display: flex; /* To align buttons side by side */\n  gap: 10px; /* Adds space between the buttons */\n  align-items: center; /* Vertically center buttons */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
