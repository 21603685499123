// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fiscal-year-container {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.fiscal-year-controls {
  display: flex;
  align-items: column;
}

.fiscal-year-title {
  margin-right: 10px;
}

.fiscal-year-picker {
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FiscalYear.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".fiscal-year-container {\n  margin-top: 20px;\n  margin-left: 10px;\n  margin-bottom: 20px;\n}\n.fiscal-year-controls {\n  display: flex;\n  align-items: column;\n}\n\n.fiscal-year-title {\n  margin-right: 10px;\n}\n\n.fiscal-year-picker {\n  font-size: 16px;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
