// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-size {
  width: 100% !important; /* Make sure it takes full width of its container */
  max-width: 100%; /* Ensure it doesn't exceed the container's width */
  padding: 8px !important; /* Same padding as your input boxes */
  font-size: 16px; /* Same font size as your input boxes */
  border: 1px solid #ccc; /* Same border as your input boxes */
  border-radius: 4px; /* Same border-radius as your input boxes */
  box-sizing: border-box; /* Ensures padding is included in the width */
}`, "",{"version":3,"sources":["webpack://./src/components/userDropDown/UserDropDown.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,mDAAmD;EAC3E,eAAe,EAAE,mDAAmD;EACpE,uBAAuB,EAAE,qCAAqC;EAC9D,eAAe,EAAE,uCAAuC;EACxD,sBAAsB,EAAE,oCAAoC;EAC5D,kBAAkB,EAAE,2CAA2C;EAC/D,sBAAsB,EAAE,6CAA6C;AACvE","sourcesContent":[".input-size {\n  width: 100% !important; /* Make sure it takes full width of its container */\n  max-width: 100%; /* Ensure it doesn't exceed the container's width */\n  padding: 8px !important; /* Same padding as your input boxes */\n  font-size: 16px; /* Same font size as your input boxes */\n  border: 1px solid #ccc; /* Same border as your input boxes */\n  border-radius: 4px; /* Same border-radius as your input boxes */\n  box-sizing: border-box; /* Ensures padding is included in the width */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
