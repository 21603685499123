import { useState } from 'react';
import '../../global.css';
import './SuggestionList.css';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import { useAppContext } from '../context/appContextProvider';

import CommentsAccordion from './CommentsAccordion'; // Import the CommentsAccordion

const what = 'SuggestionAccordion';
const SuggestionAccordion_debugThis = false;

const SuggestionAccordion = ({ suggestion, onRefreshList }) => {
  const { userID } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [active, setActive] = useState(suggestion.active);

  const handleDelete = async () => {
    const debugThis = false || SuggestionAccordion_debugThis;
    const msgPrefix = what + '.handleDelete';

    if (debugThis) {
      console.log(msgPrefix);
    }

    setDisabled(true);

    try {
      const url = base_url + `/suggestion/${suggestion._id}`;

      const body = {
        userID: userID,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url, 'body', JSON.stringify(body));
      }

      const response = await axios.delete(url, { data: body });

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      onRefreshList();
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  const getDeleteDisabled = () => {
    const debugThis = false || SuggestionAccordion_debugThis;
    const msgPrefix = what + '.getDeleteDisabled';

    const userCreated = suggestion.createdByID.toString() !== userID.toString();

    if (debugThis) {
      console.log(msgPrefix, `${suggestion.topic} ${suggestion._id}`, userCreated);
    }

    return disabled || userCreated;
  };

  const handleActivateCommenting = async (checked) => {
    const debugThis = false || SuggestionAccordion_debugThis;
    const msgPrefix = what + '.handleActivateCommenting';

    if (debugThis) {
      console.log(msgPrefix);
    }
    try {
      const url = base_url + `/suggestion/${suggestion._id}/activate`;

      const body = {
        userID: userID,
        value: checked,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url, 'body', JSON.stringify(body));
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setActive(checked);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  const getEnabledDisabledText = () => {
    return active ? 'On' : 'Off';
  };

  const allowActions = () => {
    return suggestion.createdByID.toString() === userID.toString();
  };

  const handleAddComment = async (comment) => {
    const debugThis = true || SuggestionAccordion_debugThis;
    const msgPrefix = what + '.handleAddComment';

    if (debugThis) {
      console.log(msgPrefix, comment);
    }

    try {
      const url = base_url + `/suggestion/${suggestion._id}/comment`;

      const body = {
        userID: userID,
        comment: comment,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url, 'body', JSON.stringify(body));
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      onRefreshList();
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  return (
    <div className="accordion-container">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{suggestion.topic}</h3>

        <span className={`caret ${isOpen ? 'rotate' : ''}`}>▼</span>
      </div>
      {isOpen && (
        <div className="accordion-body">
          {allowActions() && (
            <label className="checkbox-label">
              Comments
              <input
                type="checkbox"
                checked={active}
                onChange={(e) => {
                  e.stopPropagation();
                  handleActivateCommenting(e.target.checked);
                }}
                className="checkbox-allow"
              />
              {getEnabledDisabledText()}
            </label>
          )}

          {allowActions() && (
            <button
              disabled={getDeleteDisabled()}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              Delete
            </button>
          )}

          <p>{suggestion.description}</p>

          <CommentsAccordion
            showAdd={active}
            comments={suggestion.comments}
            totalComments={suggestion.totalComments}
            onAddComment={handleAddComment}
          />
        </div>
      )}
    </div>
  );
};

export default SuggestionAccordion;
