import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../context/appContextProvider';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import '../../global.css';
import './FundsDetail.css';

import FundsLedger from './FundsLedger';
import FundActions from './FundActions';
import BalanceSheet from './BalanceSheet';
import IncomeStatement from './IncomeStatement';
import FiscalYear from '../common/FiscalYear';

const fundsDetail_debugThis = false;
const what = 'fundsDetails';

const FundsDetail = () => {
  const { userID } = useAppContext();
  const { id, name } = useParams();
  const [details, setDetails] = useState([]);

  const [actionsVisible, setActionsVisible] = useState(false);
  const [ledgerVisible, setLedgerVisible] = useState(true);
  const [balanceSheetVisible, setBalanceSheetVisible] = useState(false);
  const [incomeStatementVisible, setIncomeStatementVisible] = useState(false);

  const [fiscalYear, setFiscalYear] = useState(new Date().getFullYear());

  const fetchFundDetails = useCallback(async () => {
    const debugThis = false || fundsDetail_debugThis;
    const msgPrefix = what + '.fetchFundDetails';

    try {
      const url = base_url + `/funds/id/${id}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setDetails(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [setDetails]);

  // component mounting
  useEffect(() => {
    const fectchData = async () => {
      await fetchFundDetails();
    };

    fectchData();
  }, [fetchFundDetails]);

  const onPaymentOrDisbursment = () => {
    fetchFundDetails();
  };

  const isFundManager = () => {
    return userID === details.managerID;
  };

  const toggleActionsVisibility = () => {
    setActionsVisible((prevVisible) => !prevVisible);
  };

  const toggleLedgerVisibility = () => {
    setLedgerVisible((prevVisible) => !prevVisible);
  };

  const toggleBalanceSheetVisibility = () => {
    setBalanceSheetVisible((prevVisible) => !prevVisible);
  };

  const toggleIncomeStatementVisibility = () => {
    setIncomeStatementVisible((prevVisible) => !prevVisible);
  };

  const handleYearChange = (event) => {
    setFiscalYear(event.target.value);
  };

  return (
    <div className="funds-detail-container">
      <h1 className="funds-detail-title">FUND: {details.name}</h1>

      {isFundManager() && (
        <>
          <div className="ledger-toggle" onClick={toggleActionsVisibility}>
            <span className={`caret ${actionsVisible ? 'rotate' : ''}`}>▼</span>
            {actionsVisible ? 'Hide Bookkeeping' : 'Show Bookkeeping'}
          </div>
          {actionsVisible && <FundActions details={details} onSubmit={onPaymentOrDisbursment} />}
        </>
      )}

      <FiscalYear fiscalYear={fiscalYear} handleYearChange={handleYearChange} />

      <div className="ledger-toggle" onClick={toggleLedgerVisibility}>
        <span className={`caret ${ledgerVisible ? 'rotate' : ''}`}>▼</span>
        {ledgerVisible ? 'Hide Ledger' : 'Show Ledger'}
      </div>
      {ledgerVisible && <FundsLedger fiscalYear={fiscalYear} details={details} />}

      <div className="ledger-toggle" onClick={toggleBalanceSheetVisibility}>
        <span className={`caret ${balanceSheetVisible ? 'rotate' : ''}`}>▼</span>
        {balanceSheetVisible ? 'Hide Balance Sheet' : 'Show Balance Sheet'}
      </div>
      {balanceSheetVisible && <BalanceSheet fiscalYear={fiscalYear} details={details} />}

      <div className="ledger-toggle" onClick={toggleIncomeStatementVisibility}>
        <span className={`caret ${incomeStatementVisible ? 'rotate' : ''}`}>▼</span>
        {incomeStatementVisible ? 'Hide Income Statement' : 'Show Income Statement'}
      </div>
      {incomeStatementVisible && <IncomeStatement fiscalYear={fiscalYear} details={details} />}
    </div>
  );
};

export default FundsDetail;
