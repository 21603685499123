import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

import { AppContextProvider } from './components/context/appContextProvider';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App tab="app" />
    </AppContextProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
