// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-reset-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers all child elements horizontally */
}

.input-group {
  width: 100%; /* Takes full width of the container */
  max-width: 300px; /* Ensures consistent max width */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.password-reset-input {
  width: 100%; /* Takes full width of the input-group container */
  box-sizing: border-box; /* Includes padding and border in the width */
}

.error-message {
  color: red;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/passwordReset/PasswordReset.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,4CAA4C;AACnE;;AAEA;EACE,WAAW,EAAE,sCAAsC;EACnD,gBAAgB,EAAE,iCAAiC;EACnD,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW,EAAE,kDAAkD;EAC/D,sBAAsB,EAAE,6CAA6C;AACvE;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".password-reset-container {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Centers all child elements horizontally */\n}\n\n.input-group {\n  width: 100%; /* Takes full width of the container */\n  max-width: 300px; /* Ensures consistent max width */\n  margin-bottom: 10px;\n  box-sizing: border-box;\n}\n\n.password-reset-input {\n  width: 100%; /* Takes full width of the input-group container */\n  box-sizing: border-box; /* Includes padding and border in the width */\n}\n\n.error-message {\n  color: red;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
