// LandingPage.js

import React from 'react';
import { Link } from 'react-router-dom';

import './LandingPage.css';

import bfe_image_summer from '../../images/bfe_summer.png';
import bfe_image_winter from '../../images/bfe_winter.png';

const LandingPage = ({ allowRegistration, showLoginDialog, showRegistrationDialog }) => {
  // landing page changes image based on month
  //

  const isWinter = () => {
    let today = new Date();

    // months are zero based !
    switch (today.getMonth()) {
      case 0: // jan
      case 1: // feb
      case 2: // mar
      case 9: // oct
      case 10: // nov
      case 11: // dec
        return true;
      case 3: // apr
      case 4: // may
      case 5: // jun
      case 6: // jul
      case 7: // aug
      case 8: // sep
      default:
        return false;
    }
  };

  let image = bfe_image_summer;
  if (isWinter()) {
    image = bfe_image_winter;
  }

  return (
    <div>
      <div className="photo-container">
        <img src={image} className="photo" alt="big fir estates" />
      </div>
      <div className="display-text-container">
        <p>Access to Big Fir Estates is by invitation only</p>
      </div>

      <div className="button-container">
        <div>
          <button onClick={showLoginDialog}>
            Login
          </button>
        </div>
        <div>
          {/* Conditionally render the registration button */}
          {allowRegistration && (
            <button onClick={showRegistrationDialog}>
              Register
            </button>
          )}
        </div>
      </div>
      <div className="link-container">
        <Link to={`/forgotPassword/`}>Forgot password</Link>
      </div>
    </div>
  );
};

export default LandingPage;
