// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voting-container {
  margin: 10px;
}

.voting-blurb{
  margin-left: 20px;
  margin-bottom: 20px;
}

.voting-ballot-container{
  margin-left: 20px;
  margin-bottom: 10px;
}

.voting-open-ballot-container,
.voting-closed-ballot-container {
  margin-left: 20px;
  margin-bottom: 20px;
}

.voting-notes{
  margin-top: 40px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.photo {
  width: 100%; /* Make the photo take up the full width of its container */
  max-width: 700px; /* Set a maximum width for larger screens */
  height: auto; /* Maintain aspect ratio */
}
`, "",{"version":3,"sources":["webpack://./src/components/voting/Voting.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;EAEE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW,EAAE,2DAA2D;EACxE,gBAAgB,EAAE,2CAA2C;EAC7D,YAAY,EAAE,0BAA0B;AAC1C","sourcesContent":[".voting-container {\n  margin: 10px;\n}\n\n.voting-blurb{\n  margin-left: 20px;\n  margin-bottom: 20px;\n}\n\n.voting-ballot-container{\n  margin-left: 20px;\n  margin-bottom: 10px;\n}\n\n.voting-open-ballot-container,\n.voting-closed-ballot-container {\n  margin-left: 20px;\n  margin-bottom: 20px;\n}\n\n.voting-notes{\n  margin-top: 40px;\n  margin-left: 20px;\n  margin-bottom: 20px;\n}\n\n.photo {\n  width: 100%; /* Make the photo take up the full width of its container */\n  max-width: 700px; /* Set a maximum width for larger screens */\n  height: auto; /* Maintain aspect ratio */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
