// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-delete {
  margin: 0 8px 0 0;
  align-self: flex-end;
  padding: 0px;
  background-color: #f35656;
  color: #fff;
  border: none;
  cursor: pointer;
}

.button-delete:hover {
  background-color: #e70909;
}

.button-delete:disabled {
  background-color: #beb3b3;
  cursor: default;
}

.checkbox-label {
  margin: 0 10px 0 0;
  padding: 0px;
  cursor: pointer;
}

.checkbox-allow {
  margin-left: 10px;
  margin-right: 5px;
  padding: 0px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/suggestions/SuggestionList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".button-delete {\n  margin: 0 8px 0 0;\n  align-self: flex-end;\n  padding: 0px;\n  background-color: #f35656;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n}\n\n.button-delete:hover {\n  background-color: #e70909;\n}\n\n.button-delete:disabled {\n  background-color: #beb3b3;\n  cursor: default;\n}\n\n.checkbox-label {\n  margin: 0 10px 0 0;\n  padding: 0px;\n  cursor: pointer;\n}\n\n.checkbox-allow {\n  margin-left: 10px;\n  margin-right: 5px;\n  padding: 0px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
