const FundsTable = ({ funds, managers }) => {
  const formatAmount = (value, val) => {
    let formatted = `$ ${value.toFixed(2)}`;
    let ret = '';
    if (val < 0) {
      ret = '(' + formatted + ')';
    } else {
      ret = formatted;
    }
    return ret;
  };

  return (
    <table className="fundsTable">
      <thead>
        <tr>
          <th>Fund</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {funds.map((fund) => (
          <tr key={fund._id}>
            <td data-label="Fund">
              <div className="fundName">
                <a href={`/fund/${fund._id}/${fund.name}`}>{fund.name}</a>
              </div>
              <div className="fundDescription">{fund.description}</div>
            </td>
            <td data-label="Balance">{formatAmount(fund.balance, fund.balance)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FundsTable;
