// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
  padding: 10px;
}

.about-creation-container {
  padding-left: 40px;
  padding-bottom: 20px;
}

.about-maintained-container {
  padding-left: 40px;
  padding-bottom: 20px;
}

.monthly-cost-container {
  padding-left: 40px;
  padding-bottom: 30px;
}

.about-donation-container form input[type="image"] {
  width: 150px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/about/about.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".about-container {\n  padding: 10px;\n}\n\n.about-creation-container {\n  padding-left: 40px;\n  padding-bottom: 20px;\n}\n\n.about-maintained-container {\n  padding-left: 40px;\n  padding-bottom: 20px;\n}\n\n.monthly-cost-container {\n  padding-left: 40px;\n  padding-bottom: 30px;\n}\n\n.about-donation-container form input[type=\"image\"] {\n  width: 150px;\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
