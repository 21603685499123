// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-form {
  display: flex;
  justify-content: center;
  align-items: center;

}

.forgot-password-container {
  padding: 20px;
  max-width: 500px;
  width: 100%;
  text-align: center; /* Center text and form elements */
}

.forgot-password-input {
  margin-bottom: 10px;
  width: 100%; /* Full width of the container */
  padding: 8px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}
`, "",{"version":3,"sources":["webpack://./src/components/forgotPassword/ForgotPassword.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,kBAAkB,EAAE,kCAAkC;AACxD;;AAEA;EACE,mBAAmB;EACnB,WAAW,EAAE,gCAAgC;EAC7C,YAAY;EACZ,sBAAsB,EAAE,4CAA4C;AACtE","sourcesContent":[".forgot-password-form {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.forgot-password-container {\n  padding: 20px;\n  max-width: 500px;\n  width: 100%;\n  text-align: center; /* Center text and form elements */\n}\n\n.forgot-password-input {\n  margin-bottom: 10px;\n  width: 100%; /* Full width of the container */\n  padding: 8px;\n  box-sizing: border-box; /* Ensure padding is included in the width */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
