import '../../global.css';
import './SuggestionList.css';

import SuggestionAccordion from './SuggestionAccordion';

const what = 'suggestionList';
const suggestionList_debugThis = false;

const SuggestionList = ({ suggestions, onRefreshList }) => (
  <div className="suggestion-list">
    {suggestions.map((suggestion) => (
      <SuggestionAccordion key={suggestion._id} suggestion={suggestion} onRefreshList={onRefreshList} />
    ))}
  </div>
);

export default SuggestionList;
