// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plot-clicker-container {
  display: flex;
  align-items: flex-start; /* Align items to the top (not 'left' since 'align-items' is vertical alignment) */
}

@media (max-width: 600px) {
  .plot-clicker-container {
    flex-direction: column; /* Stack the elements vertically */
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/plot/plotClicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,kFAAkF;AAC7G;;AAEA;EACE;IACE,sBAAsB,EAAE,kCAAkC;EAC5D;AACF","sourcesContent":[".plot-clicker-container {\n  display: flex;\n  align-items: flex-start; /* Align items to the top (not 'left' since 'align-items' is vertical alignment) */\n}\n\n@media (max-width: 600px) {\n  .plot-clicker-container {\n    flex-direction: column; /* Stack the elements vertically */\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
