// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.plots-container {
  justify-content: left;
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .plots-container {
    width: 100%;
    max-width: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/plot/plots2.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":["\n.plots-container {\n  justify-content: left;\n  width: 500px;\n}\n\n@media only screen and (max-width: 600px) {\n  .plots-container {\n    width: 100%;\n    max-width: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
