// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Home.css */

.home-page-container {
  position: relative;
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/Home.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["/* Home.css */\n\n.home-page-container {\n  position: relative;\n  margin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
