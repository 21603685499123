import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

import '../../global.css';
import './PasswordReset.css';

const passwordReset_debugThis = true;
const what = 'passwordReset';

const PasswordReset = () => {
  const { affiliation, id } = useParams();

  const [email, setemail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [codeError, setCodeError] = useState('');
  const [serverError, setServerError] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [type, setType] = useState('password');

  const navigate = useNavigate();
  const [changingPassword, setChangingPassword] = useState(true);

  const handleToggle = () => {
    if (isHidden()) {
      setType('text');
    } else {
      setType('password');
    }
  };

  const isHidden = () => {
    return type === 'password';
  };

  const handlePasswordReset = async () => {
    const debugThis = false || passwordReset_debugThis;
    const msgPrefix = what + '.handlePasswordReset';

    if (debugThis) {
      console.log(msgPrefix, affiliation.toString(), id.toString());
    }

    setSubmitButtonDisabled(true);

    try {
      const url = base_url + `/auth/resetPassword`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const body = {
        email: email,
        affiliation: affiliation,
        code: id,
        password: password,
      };

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data.Users));
      }

      if (response.data.Users === 'Password reset') {
        setChangingPassword(false);
      } else {
        console.log(
          msgPrefix,
          'check error response if it truly did not succeed or if the success case needs updating'
        );
      }
    } catch (error) {
      console.error(msgPrefix, 'ERROR', error);
      if (error.response.data.email) {
        setEmailError(error.response.data.email);
      }
      if (error.response.data.password) {
        setPasswordError(error.response.data.password);
      }

      if (error.response.data.code) {
        setCodeError(error.response.data.code);
      }

      if (error.response.data.Users) {
        setServerError(error.response.data.Users);
      }

      setSubmitButtonDisabled(false);
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError('');
  };

  const handleEmailChange = (value) => {
    setemail(value);
    setEmailError('');
  };

  function handleClick() {
    navigate('/');
  }

  return (
    <div className="password-reset-form">
      {changingPassword && (
        <div className="password-reset-container">
          <h1>Reset Password</h1>

          <div className="input-group">
            <label>Email:</label>
            {emailError && <div className="error-message">{emailError}</div>}
            <input
              placeholder="enter email"
              className="password-reset-input"
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label>NEW Password:</label>
            {passwordError && <div className="error-message">{passwordError}</div>}
            <div className="password-container">
              <input
                placeholder="enter password"
                className="password-input"
                type={type}
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
              <span className="password-icon-container" onClick={handleToggle}>
                {isHidden() ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          {codeError && <div className="error-message">Submit error : {codeError}</div>}
          {serverError && <div className="error-message">Error : {serverError}</div>}
          <button disabled={submitButtonDisabled} onClick={handlePasswordReset}>
            Submit
          </button>
        </div>
      )}
      {!changingPassword && (
        <div className="password-reset-container">
          <h1>Reset Password</h1>
          <p>Password changed</p>
          <button onClick={handleClick}>Retry login</button>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
