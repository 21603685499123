import React, { useState } from 'react';

import '../../global.css';
import './RegistrationDialog.css'; // Import the CSS file for styling

import axios from 'axios';
import { base_url } from '../../environment/url';

import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

const defaultEmail = require('../../environment/keys').user_default_email;
const defaultPw = require('../../environment/keys').user_dafault_pw;

const what = 'registrationDialog';
const registrationDialog_debugThis = false;

const RegistrationDialog = ({ onRegistration, onCancel, registerError }) => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState(defaultPw);
  const [passwordError, setPasswordError] = useState('');

  const [type, setType] = useState('password');

  const [password2, setPassword2] = useState(defaultPw);
  const [password2Error, setPassword2Error] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [inviteToken, setInviteToken] = useState('');
  const [inviteTokenError, setInviteTokenError] = useState('');

  const handleRegistration = async () => {
    const debugThis = false || registrationDialog_debugThis;
    const msgPrefix = what + '.handleRegistration';

    if (debugThis) {
      console.log(msgPrefix, 'Attempting registration');
    }

    let errors = 0;
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPhoneError('');
    setPasswordError('');
    setPassword2Error('');
    setInviteTokenError('');

    if (!firstName.trim()) {
      errors++;
      if (debugThis) {
        console.log(msgPrefix, 'firstName error');
      }

      setFirstNameError('First Name is required.');
    }

    if (!lastName.trim()) {
      errors++;
      if (debugThis) {
        console.log(msgPrefix, 'lastName error');
      }

      setLastNameError('Last Name is required.');
    }

    if (!phone.trim()) {
      errors++;
      if (debugThis) {
        console.log(msgPrefix, 'phone error');
      }

      setPhoneError('Phone is required.');
    }

    if (!email.trim()) {
      errors++;
      if (debugThis) {
        console.log('Email error');
      }

      setEmailError('Email invalid');
    }

    if (!password.trim()) {
      errors++;
      if (debugThis) {
        console.log('Password error');
      }

      setPasswordError('Password invalid');
    }

    if (!password2.trim()) {
      errors++;
      if (debugThis) {
        console.log('Password error');
      }

      setPassword2Error('Password invalid');
    }

    if (password.trim() !== password2.trim()) {
      errors++;
      if (debugThis) {
        console.log('Password2 error');
      }

      setPassword2Error('Passwords must match');
    }
    if (!inviteToken.trim()) {
      errors++;
      if (debugThis) {
        console.log('invite token error');
      }

      setInviteTokenError('Invite token is required.');
    }

    if (errors > 0) {
      if (debugThis) {
        console.log(msgPrefix, 'errors - returning');
      }

      return;
    }

    // Reset error state if no validation issues
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPhoneError('');
    setPasswordError('');
    setPassword2Error('');
    setInviteTokenError('');

    try {
      const url = base_url + '/users';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      var body = {
        fName: firstName,
        lName: lastName,
        phone: phone,
        email: email,
        password: password,
        password2: password2,
        timezone: 'America/Los Angeles',
        inviteToken: inviteToken,
      };

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response));
      }

      onRegistration();
    } catch (error) {
      console.error(msgPrefix, 'ERROR', error);
      if (error.response.data.fName) {
        setFirstNameError(error.response.data.fName);
      }
      if (error.response.data.lName) {
        setLastNameError(error.response.data.lName);
      }
      if (error.response.data.password) {
        setPasswordError(error.response.data.password);
      }
      if (error.response.data.password2) {
        setPassword2Error(error.response.data.password2);
      }
      if (error.response.data.phone) {
        setPhoneError(error.response.data.phone);
      }
      if (error.response.data.email) {
        setEmailError(error.response.data.email);
      }
    }
  };

  const handleToggle = () => {
    if (isHidden()) {
      setType('text');
    } else {
      setType('password');
    }
  };

  const isHidden = () => {
    return type === 'password';
  };

  return (
    <div className="registration-modal">
      <div className="registration-modal-content">
        <div>
          {registerError && <div className={`error-message ${registerError && 'error-text'}`}>{registerError}</div>}
          <h4 className="registration-h4">Register</h4>
        </div>

        <div className="registration-row">
          <div className="registration-row-item">
            <label>First Name:</label>
            {firstNameError && (
              <div className={`error-message ${firstNameError && 'error-text'}`}>{firstNameError}</div>
            )}
            <input
              placeholder="enter first name"
              className="registration-input"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="registration-row-item">
            <label>Last Name:</label>
            {lastNameError && <div className={`error-message ${lastNameError && 'error-text'}`}>{lastNameError}</div>}
            <input
              placeholder="enter last name"
              className="registration-input"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="registration-row">
          <div className="registration-row-item">
            <label>Email:</label>
            {emailError && <div className={`error-message ${emailError && 'error-text'}`}>{emailError}</div>}
            <input
              placeholder="enter email"
              className="registration-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="registration-row-item">
            <label>Cell Phone:</label>
            {phoneError && <div className={`error-message ${phoneError && 'error-text'}`}>{phoneError}</div>}
            <input
              placeholder="enter cell phone"
              className="registration-input"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className="registration-row">
          <div className="registration-row-item">
            <label>Password:</label>
            {passwordError && <div className={`error-message ${passwordError && 'error-text'}`}>{passwordError}</div>}
            <input
              placeholder="enter password"
              className="registration-input"
              type={type}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="registration-row-item">
            <label>Retype Password:</label>
            {password2Error && (
              <div className={`error-message ${password2Error && 'error-text'}`}>{password2Error}</div>
            )}
            <div className="password-container">
              <input
                placeholder="retype password"
                className="password-input"
                type={type}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              <span className="password-icon-container" onClick={handleToggle}>
                {isHidden() ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
        </div>

        <div className="registration-row">
          <div className="registration-row-item">
            <label>Invite Token:</label>
            {inviteTokenError && (
              <div className={`error-message ${inviteTokenError && 'error-text'}`}>{inviteTokenError}</div>
            )}
            <input
              placeholder="enter invite token"
              className="registration-input"
              type="text"
              value={inviteToken}
              onChange={(e) => setInviteToken(e.target.value)}
            />
          </div>
        </div>

        <div className="registration-row">
          <div className="registration-dialog-buttons-container">
            <button onClick={handleRegistration}>Submit</button>
            <button onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationDialog;
