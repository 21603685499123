// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disbursement-container {
  margin: 0;
}

.disbursement-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.disbursement-fields-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust gap as needed for spacing between fields */
  justify-content: space-between; /* Distribute fields evenly */
}

.disbursement-field {
  display: flex;
  flex-direction: column;
  flex: 1 1; /* Allow fields to grow and shrink as needed */
  min-width: 120px; /* Adjust as needed to fit smaller screens */
  max-width: 200px; /* Prevent fields from becoming too wide */
}

.disbursement-container label {
  margin-bottom: 5px;
}

.disbursement-container input {
  padding: 5px;
}

.disbursement-container button {
  margin-left: auto;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/funds/Disbursement.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS,EAAE,oDAAoD;EAC/D,8BAA8B,EAAE,6BAA6B;AAC/D;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO,EAAE,8CAA8C;EACvD,gBAAgB,EAAE,4CAA4C;EAC9D,gBAAgB,EAAE,0CAA0C;AAC9D;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".disbursement-container {\n  margin: 0;\n}\n\n.disbursement-container form {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.disbursement-fields-row {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px; /* Adjust gap as needed for spacing between fields */\n  justify-content: space-between; /* Distribute fields evenly */\n}\n\n.disbursement-field {\n  display: flex;\n  flex-direction: column;\n  flex: 1; /* Allow fields to grow and shrink as needed */\n  min-width: 120px; /* Adjust as needed to fit smaller screens */\n  max-width: 200px; /* Prevent fields from becoming too wide */\n}\n\n.disbursement-container label {\n  margin-bottom: 5px;\n}\n\n.disbursement-container input {\n  padding: 5px;\n}\n\n.disbursement-container button {\n  margin-left: auto;\n  align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
