// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LandingPage.css */

.photo-container {
  padding: 10px;
  display: flex;
  justify-content: center; /* Center the photo horizontally */
}

.photo {
  width: 100%; /* Make the photo take up the full width of its container */
  max-width: 450px; /* Set a maximum width for larger screens */
  height: auto; /* Maintain aspect ratio */
}

.display-text-container {
  text-align: center; /* Center any text within the container */
}

.button-container {
  display: flex;
  justify-content: center; /* Center the horizontally */
  margin: 20px; /* Add margin for spacing */
}

.link-container {
  display: flex;
  justify-content: center; /* Center the horizontally */
  margin-top: 20px; /* Add margin for spacing */
}

/* Media query for smaller screens */
@media only screen and (max-width: 600px) {
  .photo {
    max-width: 90%;
  }
  .button-container {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: center;
  }

  .link-container {
    display: flex;
    justify-content: center; /* Center the horizontally */
    margin-bottom: 20px; /* Add margin for spacing */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/LandingPage.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,aAAa;EACb,aAAa;EACb,uBAAuB,EAAE,kCAAkC;AAC7D;;AAEA;EACE,WAAW,EAAE,2DAA2D;EACxE,gBAAgB,EAAE,2CAA2C;EAC7D,YAAY,EAAE,0BAA0B;AAC1C;;AAEA;EACE,kBAAkB,EAAE,yCAAyC;AAC/D;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,4BAA4B;EACrD,YAAY,EAAE,2BAA2B;AAC3C;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,4BAA4B;EACrD,gBAAgB,EAAE,2BAA2B;AAC/C;;AAEA,oCAAoC;AACpC;EACE;IACE,cAAc;EAChB;EACA;IACE,sBAAsB,EAAE,wCAAwC;IAChE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,4BAA4B;IACrD,mBAAmB,EAAE,2BAA2B;EAClD;AACF","sourcesContent":["/* LandingPage.css */\n\n.photo-container {\n  padding: 10px;\n  display: flex;\n  justify-content: center; /* Center the photo horizontally */\n}\n\n.photo {\n  width: 100%; /* Make the photo take up the full width of its container */\n  max-width: 450px; /* Set a maximum width for larger screens */\n  height: auto; /* Maintain aspect ratio */\n}\n\n.display-text-container {\n  text-align: center; /* Center any text within the container */\n}\n\n.button-container {\n  display: flex;\n  justify-content: center; /* Center the horizontally */\n  margin: 20px; /* Add margin for spacing */\n}\n\n.link-container {\n  display: flex;\n  justify-content: center; /* Center the horizontally */\n  margin-top: 20px; /* Add margin for spacing */\n}\n\n/* Media query for smaller screens */\n@media only screen and (max-width: 600px) {\n  .photo {\n    max-width: 90%;\n  }\n  .button-container {\n    flex-direction: column; /* Stack vertically on smaller screens */\n    align-items: center;\n  }\n\n  .link-container {\n    display: flex;\n    justify-content: center; /* Center the horizontally */\n    margin-bottom: 20px; /* Add margin for spacing */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
