import './Home.css';

import { useAppContext } from '../context/appContextProvider';

const Home = () => {
  const { userID, affiliation } = useAppContext();

  return (
    <div>
      <div className="home-page-container">
        <div>
          <h1>Welcome to Big Fir Estates</h1>
          <p>Affiliation code: {affiliation} </p>
          <p>Your user ID is {userID}</p>
        </div>
        <br />
        <h3>History</h3>
        <p>more here</p>
      </div>
    </div>
  );
};

export default Home;
