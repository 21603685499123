import { useState, useEffect, useCallback } from 'react';

import './Funds.css';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';
import { useAppContext } from '../context/appContextProvider';

import FundsTable from './FundsTable';
import NewFund from './NewFund';

const what = 'Funds';
const funds_debugThis = false;

const Funds = () => {
  const { userID } = useAppContext();
  const [funds, setFunds] = useState([]);
  const [fundManagers, setFundManagers] = useState([]);

  const fetchFundData = useCallback(async () => {
    const debugThis = false || funds_debugThis;
    const msgPrefix = what + '.fetchFundData';

    try {
      const url = base_url + '/funds';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setFunds(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [setFunds]);

  const fetchFundManager = useCallback(async () => {
    const debugThis = false || funds_debugThis;
    const msgPrefix = what + '.fetchFundManager';

    try {
      const url = base_url + '/funds/manager';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response docs', JSON.stringify(response.data.data.docs));
      }

      setFundManagers(response.data.data.docs);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [setFundManagers]);

  // component mounting
  useEffect(() => {
    const fectchData = async () => {
      await fetchFundManager();
      await fetchFundData();
    };

    fectchData();
  }, [fetchFundData, fetchFundManager]);

  const getFundManagersDisplay = () => {
    const len = fundManagers.length;
    let label = 'Fund Manager';

    if (len > 1) {
      label = 'Fund Managers';
    }

    if (Array.isArray(fundManagers) && len > 0) {
      return (
        <div className="fundsManagerContainer">
          <h3>{label}</h3>
          <p>{fundManagers.map((manager) => `${manager.fName} ${manager.lName}`).join(', ')}</p>
        </div>
      );
    } else {
      return (
        <div className="fundsManagerContainer">
          <p>No manager available</p>
        </div>
      );
    }
  };

  const userIsFundManager = () => {
    const debugThis = false || funds_debugThis;
    const msgPrefix = what + '.userIsFundManager';

    const ret = fundManagers.some((manager) => manager._id === userID);

    if (debugThis) {
      console.log(msgPrefix, ret, JSON.stringify(userID), JSON.stringify(fundManagers));
    }

    return ret;
  };

  return (
    <div className="fundsContainer">
      <h1>Funds</h1>
      {getFundManagersDisplay()}
      <FundsTable funds={funds} managers={fundManagers} />

      {userIsFundManager() && <NewFund onSubmit={fetchFundData} />}
    </div>
  );
};

export default Funds;
