import './FiscalYear.css';

const FiscalYear = ({ fiscalYear, handleYearChange }) => {
  const generateYearOptions = () => {
    const startYear = 2019;
    const endYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    return years;
  };

  return (
    <div className="fiscal-year-container">
      <div className="fiscal-year-controls">
        <div className="fiscal-year-title">Fiscal year</div>
        <div className="fiscal-year-picker">
          <select value={fiscalYear} onChange={handleYearChange} className="year-picker">
            {generateYearOptions().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FiscalYear;
