// LoginDialog.js
import React, { useState, useEffect } from 'react';
import '../../global.css';
import './LoginDialog.css'; // Import the CSS file for styling

import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

const defaultEmail = require('../../environment/keys').user_default_email;
const defaultPw = require('../../environment/keys').user_dafault_pw;

const login_debugThis = false;

const LoginDialog = ({ onLogin, onCancel, loginError }) => {
  const debugThis = false || login_debugThis;
  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState(defaultPw);
  const [passwordError, setPasswordError] = useState('');
  const [type, setType] = useState('password');

  useEffect(() => {
    const storedEmail = localStorage.getItem('user_email');
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      setEmail(defaultEmail);
    }
  }, []);

  const handleLogin = () => {
    if (debugThis) {
      console.log('Attempting login');
    }

    let errors = 0;
    setEmailError('');
    setPasswordError('');

    if (!email.trim()) {
      errors++;
      console.log('Email error');
      setEmailError('Email required');
    }

    if (!password.trim()) {
      errors++;
      console.log('Password error');
      setPasswordError('Password required');
    }

    if (errors > 0) {
      console.log('errors');
      return;
    }

    // Reset error state if no validation issues
    setEmailError('');
    setPasswordError('');

    localStorage.setItem('user_email', email);

    onLogin({ email, password });
  };

  const handleToggle = () => {
    if (isHidden()) {
      setType('text');
    } else {
      setType('password');
    }
  };

  const isHidden = () => {
    return type === 'password';
  };

  return (
    <div className="login-modal">
      <div className="login-modal-content">
        <div>
          {loginError && <div className={`error-message ${loginError && 'error-text'}`}>{loginError}</div>}
          <h4 className="login-h4">Login</h4>
        </div>

        {/* First Row */}
        <div className="registration-row-header"></div>

        {/* Second Row */}
        <div>
          <label className="login-label">Email:</label>
          {emailError && <div className={`error-message ${emailError && 'error-text'}`}>{emailError}</div>}
          <input
            placeholder="enter email"
            className="login-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* Third Row */}
        <div className="registration-password-column">
          <label className="login-label">Password:</label>
          {passwordError && <div className={`error-message ${passwordError && 'error-text'}`}>{passwordError}</div>}
          <div className="password-container">
            <input
              placeholder="enter password"
              className="password-input"
              type={type}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="password-icon-container" onClick={handleToggle}>
              {isHidden() ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        {/* Fourth Row */}
        <div className="login-dialog-buttons-container">
          <button onClick={handleLogin}>Submit</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default LoginDialog;
