import { useState, useEffect } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';
import { useAppContext } from '../context/appContextProvider';

import './UserInfo.css';

const userInfo_debugThis = false;
const what = 'userInfo';

const UserInfo = () => {
  const { userID } = useAppContext();

  const fetchData = async () => {
    const debugThis = userInfo_debugThis || false;
    const msgPrefix = what + '.fetchData';

    try {
      const url = base_url + `/usersInfo/${userID}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    fetchData();
  }, []);

  return (
    <div className="user-info-container">
      <h1>User Info</h1>
    </div>
  );
};

export default UserInfo;
