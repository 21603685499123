import { useState } from 'react';
import '../../global.css';
import './NewFund.css';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import { useAppContext } from '../context/appContextProvider';

import FiscalMonth from '../common/FiscalMonth';

const what = 'newSuggestionAccordion';
const newSuggestionAccordion_debugThis = false;

const NewFundAccordion = ({ onSubmit }) => {
  const { userID } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  const [topic, setTopic] = useState('');
  const [topicError, setTopicError] = useState('');

  const [fiscalMonth, setFiscalMonth] = useState(0); // Default to January

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const postNewFund = async () => {
    const debugThis = false || newSuggestionAccordion_debugThis;
    const msgPrefix = what + '.postNewFund';

    try {
      const url = base_url + `/funds`;

      const body = {
        fundManagerUserID: userID,
        topic: topic,
        description: description,
        fiscalMonth: fiscalMonth,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      onSubmit();

      handleTopicChange('');
      handleDescriptionChange('');

      setIsOpen(false);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
      if (error.response.data.topic) {
        setTopicError(error.response.data.topic);
      }
      if (error.response.data.Suggestion) {
        setTopicError(error.response.data.Suggestion);
      }

      if (error.response.data.description) {
        setDescriptionError(error.response.data.description);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postNewFund();
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    setDescriptionError('');
  };

  const handleTopicChange = (value) => {
    setTopic(value);
    setTopicError('');
  };

  const handleMonthChange = (value) => {
    setFiscalMonth(value);
  };

  return (
    <div className="accordion-container">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        <div className='accordion-header-text'>
          <label>Create New Fund</label>
        </div>
        <span className={`caret ${isOpen ? 'rotate' : ''}`}>▼</span>
      </div>
      {isOpen && (
        <div className="accordion-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group topic">
              <label>Name</label>
              {topicError && <div className={`error-message ${topicError && 'error-text'}`}>{topicError}</div>}
              <input type="text" value={topic} onChange={(e) => handleTopicChange(e.target.value)} required />
            </div>
            <div className="form-group topic">
              <label>This fund's fiscal year ends on the first of</label>
              <FiscalMonth fiscalMonth={fiscalMonth} handleMonthChange={handleMonthChange} title={''} />
            </div>
            <div className="form-group description">
              <label>Description</label>
              {descriptionError && (
                <div className={`error-message ${descriptionError && 'error-text'}`}>{descriptionError}</div>
              )}
              <textarea value={description} onChange={(e) => handleDescriptionChange(e.target.value)} required />
            </div>
            <div>
              <button>Submit</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default NewFundAccordion;
