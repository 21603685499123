import React, { useState, useEffect } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import { useAppContext } from '../context/appContextProvider';

import Plots2 from './plots2';

import PlotInfo from './plotInfo';
import './plotClicker.css';

const plotClicker_debugThis = false;
const what = 'plotClicker';

const PlotClicker = () => {
  const { userID } = useAppContext();
  const [plots, setPlots] = useState([]);
  const [selectedPlot, setSelectedPlot] = useState(null);

  const fetchData = async () => {
    const debugThis = false || plotClicker_debugThis;
    const msgPrefix = what + '.fetchData';

    try {
      const url = base_url + '/plots';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setPlots(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    fetchData();
  }, []);

  const onClickHandler = (plot) => {
    setSelectedPlot(plot);
  };

  const refresh = () => {
    setSelectedPlot(null);
    fetchData();
  };

  const onAddOwner = async (plotID) => {
    const debugThis = false || plotClicker_debugThis;
    const msgPrefix = what + '.onAddOwner';

    try {
      const url = base_url + `/plots/${plotID}/owner/${userID}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.put(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      refresh();
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  const onDeleteOwner = async (plotID) => {
    const debugThis = false || plotClicker_debugThis;
    const msgPrefix = what + '.onDeleteOwner';

    try {
      const url = base_url + `/plots/${plotID}/owner/${userID}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.delete(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      refresh();
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  return (
    <div className="plot-clicker-container">
      <Plots2 onClickHandler={onClickHandler} plots={plots} selectedPlot={selectedPlot} />
      <PlotInfo plot={selectedPlot} onAddOwner={onAddOwner} onDeleteOwner={onDeleteOwner} />
    </div>
  );
};

export default PlotClicker;
