import './FiscalYear.css';

const FiscalMonth = ({ selectedMonth, handleMonthChange, title }) => {
  const generateMonthOptions = () => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months;
  };

  return (
    <div className="fiscal-month-container">
      <div className="fiscal-month-controls">
        <div className="fiscal-month-title">{title}</div>
        <div className="fiscal-month-picker">
          <select value={selectedMonth} onChange={e => handleMonthChange(Number(e.target.value))} className="month-picker">
            {generateMonthOptions().map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FiscalMonth;

