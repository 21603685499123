import React, { useState, useEffect } from 'react';
import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import './UserDropDown.css';

const userDropDown_debugThis = false;
const what = 'userDropDown';

const UserDropdown = ({ value, onChange, error }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = `${base_url}/users/page/1/limit/50`;
        const response = await axios.get(url);
        setUsers(response.data.docs);
        setLoading(false);
      } catch (err) {
        setFetchError('Failed to fetch users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (event) => {
    const debugThis = false || userDropDown_debugThis;
    const msgPrefix = what + '.handleChange';
    const selectedUserId = event.target.value;

    if (debugThis) {
      console.log(msgPrefix, 'selected user id', selectedUserId);
    }

    const selectedUser = users.find((user) => user._id === selectedUserId);

    if (debugThis) {
      console.log(msgPrefix, 'selected user', selectedUser);
    }

    onChange(selectedUser);
  };

  if (loading) return <div>Loading...</div>;
  if (fetchError) return <div>{fetchError}</div>;

  return (
    <div>
      <select
        id="user-dropdown"
        value={value ? value.id : ''}
        onChange={handleChange}
        className="input-size" /* Apply the class here */
      >
        <option value="">Select a user</option>
        {users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.fName} {user.lName} {/* Display full name, but value is the id */}
          </option>
        ))}
      </select>
    </div>
  );
};

export default UserDropdown;
