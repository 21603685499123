import axios from 'axios';
import { base_url } from '../../environment/url';

const axiosInstance = axios.create({ baseURL: base_url });

const requestRefresh = async (refresh) => {
  const response = await axios.post(`${base_url}auth/refreshtoken`, { refresh });
  return response.data.token;
};

axiosInstance.interceptors.request.use(async (config) => {
  const jwt = sessionStorage.getItem('jwt');
  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = sessionStorage.getItem('refreshToken');
      const newJwt = await requestRefresh(refreshToken);
      sessionStorage.setItem('jwt', newJwt);
      originalRequest.headers.Authorization = `Bearer ${newJwt}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
