import BallotRow from './BallotRow';

const BallotTable = ({ ballots, onVote }) => {
  const rows = [];
  ballots.forEach((ballot) => {
    rows.push(<BallotRow key={ballot._id} ballot={ballot} onVote={onVote} />);
  });

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: '60px' }}>Active</th>
          <th style={{ width: '150px' }}>Topic</th>
          <th style={{ width: '400px' }}>Description</th>
          <th style={{ width: '200px' }}>Last day of Vote</th>
          <th style={{ width: '120px' }}>Yes</th>
          <th style={{ width: '120px' }}>No</th>
          <th style={{ width: '200px' }}>Total</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default BallotTable;
