// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments-accordion {
  margin-top: 10px;
  margin-bottom: 20px;
}

.comments-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;

  border-radius: 3px;
}

.comments-body {
  margin-top: 5px;
  padding-left: 10px;
}

.comment {
  margin-bottom: 5px;
  padding: 5px;
  border-left: 2px solid #ccc;
}

.caret.rotate {
  transform: rotate(180deg);
}


.add-comment-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-comment-container {
  margin: 10px;
}

.add-comment-container input {
  flex-grow: 1; /* Makes the input field expand to fill available space */
}

.add-comment-button {
  margin: 0 8px 0 0;
  align-self: flex-end;
  padding: 0px;
  background-color: #568df3;
  color: #fff;
  border: none;
  cursor: pointer;
}

.add-comment-button:hover {
  background-color: #0d09e7;
}

.add-comment-button:disabled {
  background-color: #b3b9be;
  cursor: default;
}

.add-comment-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Adjusts the space between the elements */
}`, "",{"version":3,"sources":["webpack://./src/components/suggestions/CommentsAccordion.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;;EAEzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY,EAAE,yDAAyD;AACzE;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ,EAAE,2CAA2C;AACvD","sourcesContent":[".comments-accordion {\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.comments-header {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #f1f1f1;\n\n  border-radius: 3px;\n}\n\n.comments-body {\n  margin-top: 5px;\n  padding-left: 10px;\n}\n\n.comment {\n  margin-bottom: 5px;\n  padding: 5px;\n  border-left: 2px solid #ccc;\n}\n\n.caret.rotate {\n  transform: rotate(180deg);\n}\n\n\n.add-comment-container {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.add-comment-container {\n  margin: 10px;\n}\n\n.add-comment-container input {\n  flex-grow: 1; /* Makes the input field expand to fill available space */\n}\n\n.add-comment-button {\n  margin: 0 8px 0 0;\n  align-self: flex-end;\n  padding: 0px;\n  background-color: #568df3;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n}\n\n.add-comment-button:hover {\n  background-color: #0d09e7;\n}\n\n.add-comment-button:disabled {\n  background-color: #b3b9be;\n  cursor: default;\n}\n\n.add-comment-input-container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px; /* Adjusts the space between the elements */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
