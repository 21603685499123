import React, { useState } from 'react';
import { useAppContext } from '../context/appContextProvider';

import './FundActions.css';

import Payment from './Payment';
import Disbursement from './Disbursement';

const fundActions_debugThis = false;
const what = 'FundActions';

const FundActions = ({ details, onSubmit }) => {
  const { userID } = useAppContext();
  const [action, setAction] = useState('payment');

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  return (
    <div className="fund-action-box">
      <div className="fund-action-container">
        <div className="fund-action-title">Bookkeeping</div>

        <div className="action-selection">
          <label>
            <input type="radio" value="payment" checked={action === 'payment'} onChange={handleActionChange} />
            Income
          </label>
          <label>
            <input
              type="radio"
              value="disbursement"
              checked={action === 'disbursement'}
              onChange={handleActionChange}
            />
            Expense
          </label>

          {action === 'payment' && <Payment fundID={details._id} userID={userID} onSubmit={onSubmit} />}
          {action === 'disbursement' && <Disbursement fundID={details._id} userID={userID} onSubmit={onSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default FundActions;
