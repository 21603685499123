import { useState } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import '../../global.css';
import './Payment.css';

import UserDropDown from '../userDropDown/UserDropDown';

const payment_debugThis = false;
const what = 'payment';

const Payment = ({ fundID, userID, onSubmit }) => {
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const [method, setMethod] = useState('');
  const [methodError, setMethodError] = useState('');

  const [reference, setReference] = useState('');
  const [referenceError, setReferenceError] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);
  const [userError, setUserError] = useState('');

  const postPayment = async () => {
    const debugThis = false || payment_debugThis;
    const msgPrefix = what + '.postPayment';

    if (debugThis) {
      console.log(
        msgPrefix,
        `Processing payment ${amount} ${method} ${reference} ${selectedUser} for fund ${fundID} by user ${userID}`
      );
    }

    setAmountError('');
    setMethodError('');
    setReferenceError('');
    setUserError('');

    if (selectedUser === null) {
      setUserError('user must be selected');
      return;
    }

    try {
      const url = base_url + `/funds/${fundID}/payment`;

      const body = {
        fundManagerUserID: userID,
        fromUserID: selectedUser._id,
        amount: amount,
        method: method,
        reference: reference,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      onSubmit();

      handleAmountChange('');
      handleMethodChange('');
      handleReferenceChange('');
      handleUserChange('');
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
      if (error.response.data.topic) {
        setAmountError(error.response.data.topic);
      }

      if (error.response.data.fromUserID) {
        setUserError('select a user');
      }

      if (error.response.data.amount) {
        setAmountError(error.response.data.amount);
      }

      if (error.response.data.method) {
        setMethodError(error.response.data.method);
      }

      if (error.response.data.reference) {
        setReferenceError(error.response.data.reference);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postPayment();
  };

  const handleAmountChange = (value) => {
    setAmount(value);
    setAmountError('');
  };

  const handleMethodChange = (value) => {
    setMethod(value);
    setMethodError('');
  };

  const handleReferenceChange = (value) => {
    setReference(value);
    setReferenceError('');
  };

  const handleUserChange = (value) => {
    const debugThis = false || payment_debugThis;
    const msgPrefix = what + '.handleUserChange';

    if (debugThis) {
      console.log(msgPrefix, value);
    }

    setSelectedUser(value);
    setUserError('');
  };

  return (
    <div className="payment-container">
      <form onSubmit={handleSubmit}>
        <div className="payment-fields-row">
          <div className="payment-field">
            <label>Amount</label>
            {amountError && <div className="error-message">{amountError}</div>}
            <input type="text" value={amount} onChange={(e) => handleAmountChange(e.target.value)} required />
          </div>

          <div className="payment-field">
            <label>From User</label>
            {userError && <div className="error-message">{userError}</div>}
            <UserDropDown value={selectedUser} onChange={handleUserChange} error={userError} />
          </div>

          <div className="payment-field">
            <label>Method</label>
            {methodError && <div className="error-message">{methodError}</div>}
            <input type="text" value={method} onChange={(e) => handleMethodChange(e.target.value)} required />
          </div>

          <div className="payment-field">
            <label>Reference</label>
            {referenceError && <div className="error-message">{referenceError}</div>}
            <input type="text" value={reference} onChange={(e) => handleReferenceChange(e.target.value)} required />
          </div>
        </div>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default Payment;
