import { useState, useEffect } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';
import { useAppContext } from '../context/appContextProvider';

import voteWeights from '../../images/Vote_weights.png';

import './Voting.css';

import BallotTable from './BallotTable';

const voting_debugThis = false;
const what = 'voting';

const Voting = () => {
  const { userID } = useAppContext();
  const [ballots, setBallots] = useState([]);

  const fetchData = async () => {
    const debugThis = voting_debugThis || false;
    const msgPrefix = what + '.fetchData';

    try {
      const url = base_url + '/ballot';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setBallots(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    fetchData();
  }, []);

  const handleVote = async (userID, ballotID, choice) => {
    const debugThis = voting_debugThis || true;
    const msgPrefix = what + '.handleVote';

    if (debugThis) {
      console.log(msgPrefix, userID.toString(), ballotID.toString(), choice);
    }

    try {
      const url = base_url + `/ballot/${ballotID}/vote`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const body = {
        choice: choice,
        userID: userID,
      };

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response));
      }

      // reload the data now.
      fetchData();
    } catch (error) {
      console.error(msgPrefix, 'ERROR', error);
    }
  };

  return (
    <div className="voting-container">
      <h1>Voting</h1>
      <div className="voting-blurb">
        <p>The system will send an email with the issue details, your voter ID and how you voted</p>
        <p>Your voter id {userID}</p>
      </div>

      <div className="voting-ballot-container">
        <h2>Topics</h2>
        <p>Here are the current and past topics</p>
        <BallotTable ballots={ballots} onVote={handleVote} />
      </div>



      <div className="voting-notes">
        <h4>Voting "weight" system</h4>
        <p>To make the voting system easier to program, emails will be used to tie ownership and votes:</p>
        <img src={voteWeights} className="photo" alt="Vote weights" />
      </div>

      <div className="voting-notes">
        <h4>Voting Buttons...</h4>
        <p>If you have not voted, voting buttons will appear</p>
        <p>When there are no voting buttons, Yes/No counts will be displayed in these scenarios</p>
        <p>-- You HAVE voted </p>
        <p>-- Voting is closed</p>
        <p>-- The Topic is not active</p>
      </div>
    </div>
  );
};

export default Voting;
