// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: left;
}

.App-container {
  background-color: #b1dfeb;
}

body {
  background-color: #b1dfeb;
}

/* this will keep the text the same case*/
/* eg: before this it was all caps */
#__react-alert__ div div div {
  /* width: auto !important; */
  flex: 3 1;
  text-transform: initial !important;
  font: inherit !important;
  padding: 8px 10px;
}

.debug-text-size {
  font-size: xx-small;
}
.debug-background-yellow {
  background-color: rgb(251, 255, 0);
}
.debug-text-red {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,yCAAyC;AACzC,oCAAoC;AACpC;EACE,4BAA4B;EAI5B,SAAO;EACP,kCAAkC;EAClC,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,UAAU;AACZ","sourcesContent":[".App {\n  text-align: left;\n}\n\n.App-container {\n  background-color: #b1dfeb;\n}\n\nbody {\n  background-color: #b1dfeb;\n}\n\n/* this will keep the text the same case*/\n/* eg: before this it was all caps */\n#__react-alert__ div div div {\n  /* width: auto !important; */\n  -webkit-box-flex: 3;\n  -webkit-flex: 3;\n  -ms-flex: 3;\n  flex: 3;\n  text-transform: initial !important;\n  font: inherit !important;\n  padding: 8px 10px;\n}\n\n.debug-text-size {\n  font-size: xx-small;\n}\n.debug-background-yellow {\n  background-color: rgb(251, 255, 0);\n}\n.debug-text-red {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
