import { useState } from 'react';
import { useAppContext } from '../context/appContextProvider';

const ballotRow_debugThis = false;
const what = 'BallotRow';

const BallotRow = ({ ballot, onVote }) => {
  const { userID } = useAppContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleVote = (ballotID, choice) => {
    const msgPrefix = what + '.handleVote';
    const debugThis = ballotRow_debugThis || false;

    // don't let the user click twice
    setIsDisabled(true);

    if (debugThis) {
      console.log(msgPrefix, 'Attempting vote');
    }

    onVote(userID, ballotID, choice);
  };

  const isUserInVotes = (votes, userID) => {
    const msgPrefix = what + '.isUserInVotes';
    const debugThis = ballotRow_debugThis || false;

    const ret = votes.some((vote) => vote.user === userID);

    if (debugThis) {
      console.log(msgPrefix, ret);
    }

    return ret;
  };

  const renderVoteButton = (ballot, userID, handleVote, votingFor) => {
    const msgPrefix = what + '.renderVoteButton';
    const debugThis = ballotRow_debugThis || false;

    const voted = isUserInVotes(ballot.votes, userID);

    const date = new Date(ballot.closeDate);
    const today = new Date();
    const closed = today > date;

    const notActive = !ballot.active;

    if (debugThis) {
      console.log(msgPrefix, `voted ${voted} closed ${closed} notActive ${notActive} `);
    }

    if (voted || closed || notActive) {
      if (votingFor) {
        return ballot.for;
      } else {
        return ballot.against;
      }
    } else {
      if (votingFor) {
        return (
          <button disabled={isDisabled} onClick={() => handleVote(ballot._id, true)}>
            Vote Yes
          </button>
        );
      } else {
        return (
          <button disabled={isDisabled} onClick={() => handleVote(ballot._id, false)}>
            Vote No{' '}
          </button>
        );
      }
    }
  };

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const renderDate = (ballot) => {
    const msgPrefix = what + '.renderDate';
    const debugThis = ballotRow_debugThis || false;

    const date = new Date(ballot.closeDate);
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${day} ${month} ${year}`;

    if (debugThis) {
      console.log(msgPrefix, formattedDate);
    }
    return formattedDate;
  };

  return (
    <tr>
      <td>{ballot.active ? 'Y' : 'N'}</td>
      <td>{ballot.topic}</td>
      <td>{ballot.description}</td>
      <td>{renderDate(ballot)}</td>
      <td>{renderVoteButton(ballot, userID, handleVote, true)}</td>
      <td>{renderVoteButton(ballot, userID, handleVote, false)}</td>
      <td>{ballot.totalVotes}</td>
    </tr>
  );
};

export default BallotRow;
